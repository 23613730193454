import React from 'react'
import { styled } from '@mui/material'
import Masonry, { MasonryProps as MasonryPropsBase } from 'react-masonry-css'

export interface MasonryProps extends MasonryPropsBase {
  masonryLayout?: 'compact' | 'narrow'
  children: React.ReactNode
}

const StyledMasonry = styled(Masonry)(({ theme }) => ({
  display: 'flex',
  marginLeft: `-${theme.masonrySpacing}`,
  width: 'auto',
  ['.masonryCol']: {
    position: 'relative',
    paddingLeft: theme.masonrySpacing,
    backgroundClip: 'padding-box',
  },
}))

const MasonryGrid = ({ masonryLayout, ...props }: MasonryProps) => {
  let breakpointCols = {
    default: 1,
    99999: 4,
    1500: 3,
    1192: 2,
    768: 1,
  }
  switch (masonryLayout) {
    case 'compact':
      breakpointCols = {
        default: 1,
        99999: 4,
        1400: 2,
        868: 1,
      }
      break
    case 'narrow':
      breakpointCols = {
        default: 1,
        99999: 3,
        1400: 2,
        868: 1,
      }
      break
  }

  return (
    <StyledMasonry
      columnClassName="masonryCol"
      breakpointCols={breakpointCols}
      {...props}
    />
  )
}

export default MasonryGrid
