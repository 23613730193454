import React from 'react'
import { graphql } from 'gatsby'
import { Container } from '@mui/material'

import Section from '_core/components/atoms/layout/Section'
import MasonryGrid from '_core/components/organisms/masonry/MasonryGrid'
import Pagination from '_core/components/molecules/pagination/Pagination'

import PageTitleHero from 'public/components/organisms/hero/PageTitleHero'
import Layout from 'public/components/organisms/global/Layout'
import VacancyCard from 'public/components/molecules/cards/vacancy/VacancyCard'
import TextSection from 'public/components/organisms/sections/TextSection'
import { LandingPageContextProps } from 'public/templates/layouts/LandingPage'

interface Props {
  data: Queries.VacancyLandingQuery
  pageContext: LandingPageContextProps
}

const VacancyLanding = ({ data, pageContext }: Props) => {
  const landing = data.datoCmsLandingPage
  const vacancies = data.allDatoCmsVacancy.edges

  return (
    <Layout
      metadata={{
        seoTags: landing.seoMetaTags,
        currentPage: pageContext.currentPage,
      }}
    >
      <PageTitleHero
        mainHeadingProps={{ variant: 'h1Large' }}
        mainHeading={landing.heroMainHeading}
        smallHeading={landing.heroSmallHeading}
        imagePortrait={landing.imagePortrait.gatsbyImageData}
        imageLandscape={landing.imageLandscape.gatsbyImageData}
      />
      <TextSection
        leadText={landing.textSectionHeroText}
        smallHeading={landing.textSectionSmallHeading}
        text={landing.textSectionBodyNode.childMarkdownRemark.html}
      />
      <Section bgcolor="lightGrey" vSpace>
        <Container maxWidth={false}>
          <MasonryGrid className="grid">
            {vacancies.map((vacancy: Queries.DatoCmsVacancy) => {
              return (
                <VacancyCard
                  key={`v-${vacancy.node.originalId}`}
                  vacancy={vacancy.node}
                />
              )
            })}
          </MasonryGrid>
          <Pagination {...pageContext} />
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query VacancyLanding($skip: Int!, $perPage: Int!) {
    datoCmsLandingPage(originalId: { eq: "1462830" }) {
      ...LandingPageTitleHero
      textSectionBodyNode {
        childMarkdownRemark {
          html
        }
      }
      textSectionHeroText
      textSectionSmallHeading
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsVacancy(
      limit: $perPage
      skip: $skip
      sort: { fields: [meta___createdAt], order: DESC }
    ) {
      edges {
        node {
          ...Vacancy
        }
      }
    }
  }
`

export default VacancyLanding
