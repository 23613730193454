import React, { useRef, memo } from 'react'

import { Box, Divider, styled } from '@mui/material'

import { Share, Room } from '@mui/icons-material'
import Flex from '_core/components/atoms/layout/Flex'
import Button from '_core/components/atoms/buttons/Button'
import CardHeading from '_core/components/atoms/cards/CardHeading'
import ShareDialog, {
  ShareDialogHandle,
} from '_core/components/molecules/dialogs/ShareDialog'
import Card from '_core/components/atoms/cards/Card'
import InternalLink from '_core/components/atoms/links/InternalLink'
import InternalButtonLink from '_core/components/atoms/buttons/InternalButtonLink'

interface Props {
  vacancy: Queries.DatoCmsVacancy
}

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.masonrySpacing,
}))

const VacancyCard = ({ vacancy }: Props) => {
  const dialogRef = React.useRef<ShareDialogHandle>(null)
  return (
    <StyledCard id={vacancy.originalId}>
      <Flex flexDirection="column" px={2} pt={3} pb={1} minHeight={200}>
        <InternalLink slug={vacancy.slug} apiKey={vacancy.model.apiKey}>
          <CardHeading sx={{ m: 0, pb: '1.2rem' }}>{vacancy.title}</CardHeading>
        </InternalLink>
        <Flex alignCenter my={0.5}>
          <Room fontSize="inherit" sx={{ color: 'primary.main' }} />{' '}
          <Box ml="5px">{vacancy.location}</Box>
        </Flex>

        <Box justifySelf="flex-end" mt="auto">
          <Divider sx={{ my: 2 }} />
          <Flex>
            <Box flex="1">
              <InternalButtonLink
                slug={vacancy.slug}
                apiKey={vacancy.model.apiKey}
                color="secondary"
                sizeVariant="md"
                strongLabel
                darkLabel
                fullWidth
              >
                Find Out More
              </InternalButtonLink>
            </Box>
            <Box>
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Box>
            <Flex center flex="1">
              <Box>
                <Button
                  color="secondary"
                  sizeVariant="md"
                  round
                  aria-label="Share"
                  onClick={() => dialogRef?.current?.handleOpen()}
                >
                  <Share className="icon" fontSize="inherit" />
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Flex>

      <ShareDialog
        ref={dialogRef}
        id={vacancy.originalId}
        shareButtonsProps={{
          description: vacancy.title,
          url: `careers/${vacancy.slug}`,
          hashtags: 'JobAlert',
          contentType: 'Vacancy',
          source: 'card',
        }}
      />
    </StyledCard>
  )
}

export default memo(VacancyCard)
