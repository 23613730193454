import React from 'react'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'

import { PaginationProps } from '_core/components/molecules/pagination/Pagination'

export interface separator {
  separatorType: 'starter' | 'finisher'
}

export interface paginationItemCore {
  link: string
  ariaLabel: string
  label: React.ReactNode
  pageNum: number
}

export interface pageItem extends paginationItemCore {
  isCurrent?: boolean
}

interface createPaginationLinkProps {
  landingPathPrefix: string
  pageNum?: number | undefined
}
export const createPaginationLink = ({ landingPathPrefix, pageNum }: createPaginationLinkProps) => {
  let page = ''
  if (pageNum && pageNum !== undefined && pageNum != 1 && pageNum > -1) {
    page = `/${pageNum}`
  }
  return landingPathPrefix + page
}

interface createPaginationItemsProps {
  length: number
  currentPage: number
  landingPathPrefix: string
  increment?: number
}

const createPaginationItems = ({
  length,
  currentPage,
  landingPathPrefix,
  increment,
}: createPaginationItemsProps) => {
  let inc = 1
  if (increment) {
    inc = increment
  }
  return Array.from({ length }, (_, i) => {
    const pageNum = i + inc === 1 ? 1 : i + inc
    return {
      link: createPaginationLink({
        landingPathPrefix: landingPathPrefix,
        pageNum: pageNum,
      }),
      label: i + inc,
      ariaLabel: `Go to page ${i + inc}`,
      isCurrent: currentPage === i + inc,
      pageNum: pageNum,
    }
  })
}

export const createPaginationStructure = ({
  numPages,
  currentPage,
  landingPathPrefix,
}: Omit<PaginationProps, 'handleClick' | 'dynamic'>) => {
  let paginationStructure: (paginationItemCore | pageItem | separator)[] = []
  let lastLink = undefined

  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPageNum = currentPage - 1 === 1 ? 1 : currentPage - 1
  const nextPageNum = currentPage + 1

  const nextPageLink = isLast
    ? ''
    : createPaginationLink({
        landingPathPrefix: landingPathPrefix,
        pageNum: nextPageNum,
      })
  const firstPageLink = landingPathPrefix

  // Previous Page Nav
  if (!isFirst) {
    paginationStructure = [
      ...paginationStructure,
      {
        link: createPaginationLink({
          landingPathPrefix: landingPathPrefix,
          pageNum: prevPageNum,
        }),
        ariaLabel: 'Go to the previous page',
        label: <NavigateBefore />,
        pageNum: prevPageNum,
      },
    ]
  }
  if (currentPage > 3) {
    paginationStructure = [
      ...paginationStructure,
      {
        link: createPaginationLink({
          landingPathPrefix: landingPathPrefix,
        }),
        ariaLabel: `Go to page 1`,
        label: 1,
        pageNum: 1,
      },
    ]
  }

  if (numPages <= 3) {
    paginationStructure = [
      ...paginationStructure,
      ...createPaginationItems({
        length: numPages,
        currentPage: currentPage,
        landingPathPrefix: landingPathPrefix,
      }),
    ]
  } else {
    // We have a situation where we have to show the first
    // item, three items around the current one
    // and also the last item
    if (currentPage <= 3) {
      // If the current one is closer to the start
      paginationStructure = [
        ...paginationStructure,
        ...createPaginationItems({
          length: 3,
          currentPage: currentPage,
          landingPathPrefix: landingPathPrefix,
        }),
        {
          separatorType: 'starter',
        },
      ]
      lastLink = true
    } else if (currentPage > numPages - 3) {
      paginationStructure = [
        ...paginationStructure,
        {
          separatorType: 'starter',
        },
        ...createPaginationItems({
          length: 3,
          currentPage: currentPage,
          landingPathPrefix: landingPathPrefix,
          increment: numPages - 2,
        }),
      ]
    } else {
      paginationStructure = [
        ...paginationStructure,
        {
          separatorType: 'starter',
        },
        ...createPaginationItems({
          length: 3,
          currentPage: currentPage,
          landingPathPrefix: landingPathPrefix,
          increment: currentPage - 1,
        }),
        {
          separatorType: 'finisher',
        },
      ]
      lastLink = true
    }
  }

  if (lastLink) {
    paginationStructure = [
      ...paginationStructure,
      {
        link: createPaginationLink({
          landingPathPrefix: landingPathPrefix,
          pageNum: numPages,
        }),
        ariaLabel: `Go to page ${numPages}`,
        label: numPages,
        pageNum: numPages,
      },
    ]
  }

  if (!isLast) {
    paginationStructure = [
      ...paginationStructure,
      {
        link: createPaginationLink({
          landingPathPrefix: landingPathPrefix,
          pageNum: nextPageNum,
        }),
        ariaLabel: 'Go to the next page',
        label: <NavigateNext />,
        pageNum: nextPageNum,
      },
    ]
  }

  return paginationStructure
}
