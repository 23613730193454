import React, { memo } from 'react'
import { Box, ButtonGroup, useTheme } from '@mui/material'
import { lighten } from 'polished'

import {
  createPaginationStructure,
  paginationItemCore,
  pageItem,
  separator,
} from '_core/helpers/pagination'
import InternalButtonLink, {
  InternalButtonLinkProps,
} from '_core/components/atoms/buttons/InternalButtonLink'
import Button, { ButtonProps } from '_core/components/atoms/buttons/Button'

interface StaticPagerButtonProps {
  slug: string
  dynamic?: never | false
  handleClick?: never
}
interface DynamicPagerButtonProps {
  dynamic: true
  handleClick: React.MouseEventHandler<HTMLElement>
  slug?: never
}

type PagerButtonProps = {
  children: React.ReactNode
  ariaLabel: string
  className: string
  buttonProps: Omit<InternalButtonLinkProps, 'slug' | 'children'> | ButtonProps
} & (StaticPagerButtonProps | DynamicPagerButtonProps)

export interface PaginationProps {
  numPages: number
  currentPage: number
  landingPathPrefix: string
  dynamic: true | never
  handleClick: React.MouseEventHandler<HTMLElement>
}

const PagerButton = ({
  dynamic,
  slug,
  children,
  ariaLabel,
  className,
  buttonProps,
  handleClick,
}: PagerButtonProps) => {
  return (
    <>
      {dynamic ? (
        <Button
          aria-label={ariaLabel}
          className={`${className} pagination-button`}
          onClick={handleClick}
          {...buttonProps}
        >
          {children}
        </Button>
      ) : (
        <InternalButtonLink
          slug={slug}
          aria-label={ariaLabel}
          className={className}
          {...buttonProps}
        >
          {children}
        </InternalButtonLink>
      )}
    </>
  )
}

const Pagination = ({
  numPages,
  currentPage,
  landingPathPrefix,
  dynamic,
  handleClick,
}: PaginationProps) => {
  const theme = useTheme()
  const paginationStructure = createPaginationStructure({
    numPages: numPages,
    currentPage: currentPage,
    landingPathPrefix: landingPathPrefix,
  })

  const buttonProps: ButtonProps = {
    sizeVariant: 'xl',
    colorVariant: 'outline-dark',
    // sharp: true,
    smallRadius: true,
    sx: {
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '1.8rem',
        paddingRight: '1.8rem',
        width: 'auto !important',
        minWidth: 'auto !important',
      },
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '2rem',
        paddingRight: '2rem',
      },
      // backgroundColor: 'white',
      ['&.Mui-disabled']: {
        color: 'text.primary',
      },
      ['&.is-current']: {
        // color: 'textDark',
        fontWeight: theme.typography.fontWeightSemiBold,
        [':after']: {
          display: 'block',
          content: '""',
          width: '100%',
          height: '3px;',
          backgroundColor: theme.palette.primary.main,
          position: 'absolute',
          left: 0,
          bottom: 0,
        },
      },
    },
  }

  return (
    <>
      {numPages && paginationStructure.length > 1 && (
        <Box
          textAlign="center"
          mt={{ xs: 4, sm: 5, md: 6, lg: 7, xl: 8 }}
          mb={{ xs: 0, sm: 1, md: 2, lg: 3, xl: 4 }}
        >
          <ButtonGroup
            sx={{
              backgroundColor: 'common.white',
              textAlign: 'center',
              ['.MuiButtonGroup-grouped:not(:last-of-type):hover']: {
                borderColor: lighten(0.5, theme.palette.textDark),
              },
            }}
          >
            {paginationStructure.map(
              (item: paginationItemCore | pageItem | separator, index) => {
                if ('separatorType' in item) {
                  let component = { component: 'a' }
                  if (dynamic) {
                    // @ts-ignore
                    component = {}
                  }
                  return (
                    <Button
                      {...component}
                      disabled
                      key={`pagination-separator-${index}`}
                      {...buttonProps}
                    >
                      &hellip;
                    </Button>
                  )
                }
                if ('link' in item) {
                  return (
                    <PagerButton
                      key={`pagination-item-${index}`}
                      slug={item.link}
                      buttonProps={{ ...buttonProps }}
                      className={`pagination-link ${
                        'isCurrent' in item && item.isCurrent
                          ? 'is-current'
                          : ''
                      }`}
                      ariaLabel={item.ariaLabel}
                      handleClick={() => handleClick(item.pageNum)}
                      dynamic={dynamic}
                    >
                      {item.label}
                    </PagerButton>
                  )
                }
              }
            )}
          </ButtonGroup>
        </Box>
      )}
    </>
  )
}

export default memo(Pagination)
